// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { Link } from 'gatsby';
import { emStyles } from '@type';

// Exports
// ------
const sharedButtonStyles = (props) => css`
	border: none;
	background-color: none;
	padding: 0;
	${breakup.medium`
		background-color: rgba(0, 0, 0, 0.1);
		border: 1px solid rgba(255, 255, 255, 0.1);
		padding: 1.3rem;
	`}
	border-radius: 0.7rem;
	display: flex;
	align-items: center;

	position: relative;
	cursor: pointer;

	display: flex;
	justify-content: center;
	width: fit-content;

	span {
		${emStyles}
		position: relative;
		z-index: 3;
		font-size: 1.2rem !important;
		text-transform: none;
		letter-spacing: 1px;
		font-weight: ${props.theme.reg};
		white-space: nowrap;

		+ span {
			margin: 0 0 0 0.6rem;
			font-weight: ${props.theme.reg};
		}
	}
	svg {
		margin-left: 0.6rem;
		width: 1rem;
		height: 1rem;
		fill: ${props.theme.bc4};
	}
`;

export const ButtonInt = styled(Link)(
	(props) => css`
		${sharedButtonStyles}
	`
);

export const ButtonExt = styled.a(
	(props) => css`
		${sharedButtonStyles}
	`
);
