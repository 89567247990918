// Imports
// ------
import React from 'react';
import { observer } from 'mobx-react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

// Styles
// ------
import { ButtonExt, ButtonInt } from './styles';
import { Label, GradientText } from '@tackl';
import Icons from '../../../Icons';

const StyledLabel = styled(Label)`
	margin: 0;
	background-color: transparent;
	padding: 0;
`;

// Component
// ------
const InnerContent = ({ label }) => (
	<StyledLabel className='label'>
		<GradientText data-name={`New: ${label}`}>
			New: {label} <Arrow />
		</GradientText>
	</StyledLabel>
);

const Arrow = () => <Icons type='arrow-external' />;

const Notification = ({ link, label, isExternal }) => {
	return (
		<motion.div
			initial={{ opacity: 0, x: `-1rem` }}
			animate={{ opacity: 1, x: `0%` }}
			transition={{ duration: 1, delay: 0.25 }}>
			{isExternal ? (
				<ButtonInt to={link}>
					<InnerContent label={label} />
				</ButtonInt>
			) : (
				<ButtonExt
					href={link}
					aria-label={label}
					rel='noopener noreferrer'
					target='_blank'>
					<InnerContent label={label} />
				</ButtonExt>
			)}
		</motion.div>
	);
};

export default observer(Notification);
